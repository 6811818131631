.dashboard-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: transparent;
}

.header-dropdown{
  display: flex;
  justify-content: center;
  align-items: center;
  p{
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.configuration-activity {
  list-style: none;
  display: inline-block;
  position: relative;
  height: 100%;
  padding: 20px 16px;

  &.active{
    background-color: rgba(51, 51, 51, 0.2);

  }

  &:after {
    opacity: 0;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.2);
    transition: 300ms ease-in-out all;
  }

  &:hover {
    cursor:pointer;
    &:after {
      opacity: 1;
      transition: 300ms ease-in-out all;
    }
  }
}

.dashboard-list-search-container {
  display: block;
  width: 100%;
  height: 50px;
  margin: 10px 0;
}

.dashboard-list-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  height: 100%;
  border-radius: 5px;
  padding: 0 10px;
  width: 20%;
  min-width: 200px;

  input {
    height: 90%;
    width: 80%;
    border: none;
    outline-width: 0;
  }
}

.dashboard-table {
  width: 100%;
  height: 100%;
  //min-width: 1200px;
  border-collapse: collapse;

  tr, table, td, th {
    border: 1px solid rgba(34, 36, 38, .15);
    border-collapse: collapse;
  }
}

.dashboard-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 200px;
  background-color: #F9FAFB;

  th {
    padding: 10px;
    text-align: left;
  }
}

.inactive{
  .hover {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.hover {
  &:hover {
    cursor: pointer;
  }
}

.dashboard-list-wrapper {
  width: 100%;
  height: auto;
  min-height: 50px;
  max-height: 300px;
  border-bottom: 2px solid;
  background-color: white;

  &:hover {
    background-color: #f2f2f2;
  }

  &.inactive{
    background-color: #e4e4e4;
    cursor: not-allowed;
    p {
      color: gray;
    }
  }

  &.positive {
    background-color: #FCFFF5;

    p {
      color: green;
    }

    &:hover {
      background-color: #f0ffe8;
    }
  }

  &.negative {
    background-color: #ffd7d8;

    p {
      color: red;
    }

    &:hover {
      background-color: #ffcdd3;
    }
  }

  td {
    text-align: left;
    padding: 10px;
    align-items: center;
    justify-content: center;

    .dashboard-button-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}

.dashboard-table-message-wrapper {
  display: none;
  width: 100%;

  &.open {
    display: table-row;
    height: auto;
    border: none;
    min-height: 50px;
  }

  .dashboard-message {
    width: 100%;
    height: 100%;
  }
}


.dashboard-list-inner-wrapper {
  //display: grid;
  width: 100%;
  height: auto;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 1300px) {
  .dashboard-container{
    overflow-x: scroll;
  }
}
